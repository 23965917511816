<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Faaliyet Ekle</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row cols="12" dense>
          <v-col sm="4">
            <rs-text-field
              label="Başlık"
              v-model="formData.title"
              :rules="[
                rules.required,
                rules.minLength(formData.title, 5),
                rules.maxLength(formData.title, 250),
              ]"
            />
          </v-col>

          <v-col sm="4">
            <rs-datepicker
              :rules="[rules.required]"
              required
              v-model="formData.started_on"
              label="Başlangıç Tarihi"
            />
          </v-col>

          <v-col sm="4">
            <rs-datepicker
              :rules="[rules.required]"
              required
              v-model="formData.item_date"
              label="Bitiş Tarihi"
            />
          </v-col>

          <v-col sm="12">
            <rs-textarea
              label="Metin"
              v-model="formData.text"
              :rules="[
                rules.minLength(formData.text, 5),
                rules.maxLength(formData.text, 1500),
              ]"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";

export default {
  name: "OperatingItemForm",
  mixins: [hasForm],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  methods: {
    show() {
      this.formData = {
        title: null,
        text: null,
        started_on: new Date().toJSON().split("T")[0],
        item_date: new Date().toJSON().split("T")[0],
      };

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    hide() {
      this.formData = {};
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);
      formData.cluster_id = this.clusterId;

      this.$api
        .post("reports/operating-report-items", formData)
        .then((response) => {
          this.$toast.success("Kaydedildi");
          this.$nextTick(() => {
            this.$emit("saved", response.data.data);
            this.$emit("created", response.data.data);
          });
          if (event && event.closeOnSave) {
            this.hide();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
