<template>
  <v-card :loading="isLoading">
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Faaliyetler"
            icon="mdi-format-list-numbered"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            hide-edit
            :search.sync="search.query"
            @click:edit="handleEditClick"
            add-route="#"
            @click:add="handleAddClick"
            v-bind="titleBarAttrs"
            show-delete
            delete-confirmed
            :delete-enabled="selectedItems.length > 0"
            @click:delete="handleDeleteClick"
          />

          <OperatingItemForm ref="operatingItemForm" @saved="loadList" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals :headers="headers" :totals="footTotals" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.item_date="{ item, value }">
          <div
            class="cursor-pointer"
            @click="handleEditClick(item, 'item_date')"
            v-if="!item.editing"
          >
            <rs-table-cell-date :value="value" />
          </div>
          <rs-datepicker
            required
            v-model="item.item_date"
            label="Tarih"
            v-else
            :loading="item.isLoading"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.started_on="{ item, value }">
          <div
            class="cursor-pointer"
            @click="handleEditClick(item, 'started_on')"
            v-if="!item.editing"
          >
            <rs-table-cell-date :value="value" />
          </div>
          <rs-datepicker
            required
            v-model="item.started_on"
            label="Tarih"
            v-else
            :loading="item.isLoading"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.title="{ item, value }">
          <div
            class="cursor-pointer"
            @click="handleEditClick(item, 'title')"
            v-if="!item.editing"
          >
            {{ value }}
          </div>
          <rs-text-field
            required
            v-model="item.title"
            label="Başlık"
            v-else
            :loading="item.isLoading"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.text="{ item, value }">
          <div
            class="cursor-pointer"
            @click="handleEditClick(item, 'text')"
            v-if="!item.editing"
          >
            {{ value }}
          </div>
          <rs-textarea
            v-model="item.text"
            label="Başlık"
            v-else
            :loading="item.isLoading"
            rows="5"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ item, value }">
          <div
            class="cursor-pointer"
            @click="handleEditClick(item, 'amount')"
            v-if="!item.editing"
          >
            <rs-table-cell-number price :value="value" />
          </div>
          <rs-money-field
            v-model="item.amount"
            label="Tutar"
            v-else
            :loading="item.isLoading"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <rs-action
            @click.prevent="handleEditClick(item)"
            v-if="!item.editing"
            title="Düzenle"
            icon
          >
            <v-icon>mdi-pencil</v-icon>
          </rs-action>

          <rs-action
            @click.prevent="handleSaveClick(item)"
            v-else
            title="Kaydet"
            icon
            :is-loading="item.isLoading"
            :disabled="item.isLoading"
          >
            <v-icon>mdi-content-save</v-icon>
          </rs-action>

          <rs-action
            @click.prevent="item.editing = false"
            v-if="item.editing"
            title="İptal"
            icon
            :is-loading="item.isLoading"
            :disabled="item.isLoading"
          >
            <v-icon>mdi-close</v-icon>
          </rs-action>

          <rs-action-delete
            confirmed
            @click="handleDeleteClick(item)"
            v-if="!item.editing"
            title="Sil"
            icon
            :is-loading="item.isLoading"
            :disabled="item.isLoading"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_user_card="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_subscription="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount_for_cluster="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.commission="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";
import OperatingItemForm from "./forms/OperatingItemForm.vue";

export default {
  name: "OperatingItemList",
  mixins: [filtersToURL, hasExport, hasPermissions],
  components: { OperatingItemForm },
  computed: {
    ...mapGetters(["careTypeList", "clusterId"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
    clusterId: {
      handler() {
        this.loadList();
      },
    },
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  data() {
    return {
      options: {
        sortBy: ["item_date"],
        sortDesc: [true],
      },
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      titleBarAttrs: {
        exportUrl: "/reports/operating-report-items",
        exportParams: this.getParams,
      },
      headers: [
        {
          text: "Başlangıç",
          value: "started_on",
          searchable: "date",
          width: "70px",
        },
        {
          text: "Bitiş",
          value: "item_date",
          searchable: "date",
          width: "70px",
        },
        {
          text: "Başlık",
          value: "title",
          searchable: "text",
          width: "180px",
        },
        {
          text: "Metin",
          value: "text",
          searchable: "text",
        },
        {
          text: "Tür",
          value: "care_type_name",
          searchable: "multiselect",
          options: () => this.careTypeList,
          searchValue: "care_type_ids",
        },
        {
          text: "Tip",
          value: "care_subtype_name",
          searchable: "text",
        },
        {
          text: "Tedarikçi",
          value: "provider_name",
          searchable: "text",
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
          width: "180px",
        },
        {
          text: "",
          value: "actions",
          searchable: false,
          sortable: false,
          width: "110px",
          align: "center",
        },
      ],
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;

      return params;
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.selectedItems = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("/reports/operating-report-items", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data.map((row) => {
              row.isLoading = false;
              row.editing = false;
              return row;
            });
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleAddClick() {
      this.$refs.operatingItemForm.show();
    },
    handleEditClick(item) {
      if (!item.isLoading) {
        item.editing = true;
      }
    },
    handleSaveClick(item) {
      if (item.isLoading) {
        return false;
      }

      item.isLoading = true;

      const formData = {
        amount: item.amount,
        item_date: item.item_date,
        started_on: item.started_on,
        title: item.title,
        text: item.text,
      };

      // const formData = {};
      // formData[key] = item[key];

      this.$api
        .put(`/reports/operating-report-items/${item.id}`, formData)
        .then(() => {
          item.editing = false;
          this.$toast.success(this.$t("saved"));
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    handleDeleteClick(item) {
      let params;

      if (item && item.id) {
        params = { ids: [item.id] };
      } else if (!this.selectedItems.length) {
        return false;
      } else {
        params = {
          ids: this.selectedItems.map((item) => item.id),
        };
      }

      this.isLoading = true;

      this.$api
        .delete("reports/operating-report-items", params)
        .then(() => {
          this.$toast.success("Seçilen faaliyetler silindi");
          this.$emit("updated");
          this.selectedItems = [];
          setTimeout(() => this.loadList());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
